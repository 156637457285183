import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarDataAdmin } from "./SidebarDataAdmin";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import Cookies from "universal-cookie";
import { SidebarDataCliente } from "./SidebarDataCliente";
import logo from "../imagenes/LOGO-Icontec-2019_ver_white.png";

const Nav = styled.div`
  background: #0085ca;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  width: 100%;
  transition: 350ms;
  z-index: 10;
`;
const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const SidebarNav = styled.nav`
  background: #0085ca;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;
const SidebarWrap = styled.div`
  width: 100%;
`;
const Logo = styled.img`
  height: 60px;
  margin-right: 2rem;
  margin-left: auto; /* Empuja la imagen hacia la derecha */
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const cookie = new Cookies();
  if (cookie.get("nitNumber") == 8600123361) {
    return (
      <Fragment>
        <IconContext.Provider value={{ color: "#fff" }}>
          <Nav>
            <NavIcon to="#">
              <FaIcons.FaBars onClick={showSidebar} />
            </NavIcon>
            <Logo src={logo} alt="Logo" />
          </Nav>
          <SidebarNav sidebar={sidebar}>
            <SidebarWrap>
              <NavIcon to="#">
                <AiIcons.AiOutlineCloseCircle onClick={showSidebar} />
              </NavIcon>
              {SidebarDataAdmin.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </Fragment>
    );
  } else
    return (
      <Fragment>
        <IconContext.Provider value={{ color: "#fff" }}>
          <Nav>
            <NavIcon to="#">
              <FaIcons.FaBars onClick={showSidebar} />
            </NavIcon>
          </Nav>
          <SidebarNav sidebar={sidebar}>
            <SidebarWrap>
              <NavIcon to="#">
                <AiIcons.AiOutlineCloseCircle onClick={showSidebar} />
              </NavIcon>
              {SidebarDataCliente.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </Fragment>
    );
};

export default Sidebar;
